<template>
  <div>
    <b-card>
      <own-table
        ref="flashsaleTable"
        name-table="flash-sale"
        :table-columns="columns"
        :disable-create-btn="false"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        sort-by="created_at"
        sort-direction="desc"
        :export-excel="false"
        @searchFieldOnChange="searchOnChange"
        @evt-add="
          () =>
            $router.push({
              name: 'master-flashsale-create',
            })
        "
      >
        <template #cell(status)="data">
          <b-badge :variant="statusVariant(data.data.item.status).variant">
            {{ statusVariant(data.data.item.status).label }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              menu-class="position-absolute"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="setStatus(data.data.item)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">{{
                  parseInt(data.data.item.status) === 1
                    ? 'Set Tidak Aktif'
                    : 'Set Aktif'
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  $router.push({
                    name: 'master-flashsale-view',
                    params: { id: data.data.item.id },
                  })
                "
              >
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(data.data.item.id)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BFormInput,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardTitle,
  BTooltip,
  BAvatar,
} from 'bootstrap-vue'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import {
  computed,
  ref,
  onUnmounted,
  watch,
  watchEffect,
} from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import vSelect from 'vue-select'
import { formatDate, title } from '@core/utils/filter'
import {
  statusOrder,
  statusOrderVariant,
  statusVariant,
} from '@core/utils/constant'
import service from '@/components/Table/service'
import Vue from 'vue'
// import FilterFields from '../components/filter/Filter.vue'
// import calonAgenStoreModule from './calonAgenStoreModule'

export default {
  components: {
    BCard,
    // FilterFields,
    OwnTable,
    BFormInput,
    vSelect,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCardTitle,
    BTooltip,
    BAvatar,
  },
  setup() {
    const flashsaleTable = ref(null)
    const queryFilter = ref('')
    const columns = [
      {
        label: 'Keterangan',
        key: 'ket',
        field: 'ket',
      },
      {
        label: 'Start',
        key: 'start',
        field: 'start',
      },
      {
        label: 'End',
        key: 'end',
        field: 'end',
      },
      {
        label: 'Status',
        key: 'status',
        field: 'status',
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ]

    const searchOnChange = val => {
      queryFilter.value = `${val !== '' ? `&q=${val}` : ''}`
    }

    const onDelete = id => {
      Vue.swal({
        title: 'Are you sure to delete it?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Yes, deleted it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        preConfirm: () => service.destroy({ url: 'flash-sale', id }),
      }).then(async result => {
        console.log(result)
        if (result.value) {
          flashsaleTable.value.refetchData()
        } else if (result.dismiss === 'cancel') {
          Vue.swal({
            title: 'Cancelled',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }

    const setStatus = data => {
      const form = data
      form.status = data.status === 1 ? 0 : 1
      service
        .update({ url: 'flash-sale', data: form })
        .then(res => {
          flashsaleTable.value.refetchData()
        })
        .catch(err => {
          const element = Vue.component('description', {
            render: h => serializeErrorMessage(h, err),
          })
          const toast = createToastInterface({
            timeout: 6000,
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Edit Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              description: element,
            },
          })
        })
    }

    return {
      flashsaleTable,
      queryFilter,
      searchOnChange,
      formatDate,
      statusOrder,
      statusOrderVariant,
      columns,
      statusVariant,
      onDelete,
      setStatus,
    }
  },
}
</script>
